export const environment = {
  production: false,
  useEmulators: false,
  client: 'BCP',
  baseUrl: 'https://us-central1-bcp-ahorro-qa.cloudfunctions.net',
  currency: { singular: 'Sol', plural: 'Soles', symbol: 'S/.' },
  firebase: {
    apiKey: 'AIzaSyA7AqGKW3RnIQMjcMhqgzgPOZIW_ZzcMFA',
    authDomain: 'bcp-ahorro-qa.firebaseapp.com',
    projectId: 'bcp-ahorro-qa',
    storageBucket: 'bcp-ahorro-qa.appspot.com',
    messagingSenderId: '724148480457',
    appId: '1:724148480457:web:dd7e831b8f25a94b6e2018'
  }
};