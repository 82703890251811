import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutComponent } from './core/components/admin-layout/admin-layout.component';
import { AdminGuard } from './core/guards/admin.guard';
import { CheckLoginGuard } from './core/guards/check-login.guard';
import { ConfigGuard } from './core/guards/config.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [CheckLoginGuard],
    component: AdminLayoutComponent,
    children: [
      {
        path: 'groups',
        data: {field: 'showGroups'},
        canActivate: [ConfigGuard],
        loadChildren: () =>
          import('./features/groups/groups.module').then((m) => m.GroupsModule),
      },
      {
        path: 'analytics',
        loadChildren: () =>
          import('./features/analytics/analytics.module').then(
            (m) => m.AnalyticsModule
          ),
      },
      {
        path: 'shops',
        data: {field: 'showShops'},
        canActivate: [ConfigGuard],
        loadChildren: () =>
          import('./features/shops/shops.module').then((m) => m.ShopsModule),
      },
      {
        path: 'admins',
        data: {field: 'showAdmins'},
        canActivate: [ConfigGuard, AdminGuard],
        canLoad: [AdminGuard],
        loadChildren: () =>
          import('./features/admins/admins.module').then((m) => m.AdminsModule),
      },
      {
        path: 'custom-metrics',
        data: {field: 'customMetrics'},
        canActivate: [ConfigGuard],
        loadChildren: () =>
          import('./features/custom-metrics/custom-metrics.module').then((m) => m.CustomMetricsModule),
      },
      {
        path: 'applications',
        loadChildren: () =>
          import('./features/application/application.module').then((m) => m.ApplicationModule),
      },
      {
        path: 'users',
        data: {field: 'showUsers'},
        canActivate: [ConfigGuard, AdminGuard],
        loadChildren: () =>
          import('./features/users/presentation/users.module').then(m => m.UsersModule)
      },
      {
        path: 'user-payment-late',
        data: {field: 'showUserPaymentLate'},
        canActivate: [ConfigGuard],
        loadChildren: () =>
          import('./features/user-payment-late/presentation/user-payment-late.module').then(m => m.UserPaymentLateModule)
      },
      {
        path: 'user-funnel',
        data: {field: 'showUserFunnel'},
        canActivate: [ConfigGuard],
        loadChildren: () =>
          import('./features/user-funnel/presentation/user-funnel.module').then(m => m.UserFunnelModule)
      },
      {
        path: 'juntas-funnel',
        data: {field: 'showJuntasFunnel'},
        canActivate: [ConfigGuard],
        loadChildren: () =>
          import('./features/juntas-funnel/presentation/juntas-funnel.module').then(m => m.JuntasFunnelModule)
      },
      {
        path: 'usuer-usual-funnel',
        data: {field: 'showUsualUsers'},
        canActivate: [ConfigGuard],
        loadChildren: () =>
          import('./features/user-usual-funnel/user-usual-funnel.module').then(m => m.UserUsualFunnelModule)
      },
      {
        path: 'bsol-user-funnel',
        data: {field: 'showBsUsersFunnel'},
        canActivate: [ConfigGuard],
        loadChildren: () =>
          import('./features/bsol-user-funnel/bsol-user-funnel.module').then(m => m.BsolUserFunnelModule)
      },
      {
        path: 'bcp',
        loadChildren: () =>
          import('./features/bcp/bcp.module').then(m => m.BcpModule)
      },
      {
        path:'report-user',
        data:{field: 'showReportUser'},
        canActivate:[ConfigGuard],
        loadChildren: ()=> import('./features/report-user/presentation/report-user.module').then(m=>m.ReportUserModule)
      }
    ],
  },
  {
    path: 'saving-metric',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./features/bcp/saving-metric/presentation/saving-metric.module').then(
        (m) => m.SavingMetricModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {bindToComponentInputs: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
