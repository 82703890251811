export class DashboardConfig {
  logoUrl?: string;
  title?: string;
  showAdmins?: boolean;
  showShops?: boolean;
  showUsers?: boolean;
  showGroups?: boolean;
  showCredits?: boolean;
  requestPasswordChangeUrl?: string;
  customMetrics?: boolean;
  showUserPaymentLate?: boolean;
  showUserFunnel?: boolean;
  showJuntasFunnel?: boolean;
  showUsualUsers?: boolean;
  showBsUsersFunnel?: boolean;
  showEndUsers?: boolean;
  showHeaderNotification?: boolean;
  savingPlans?: boolean;
  paymentList?: boolean;
  showReportUser?:boolean;
  constructor() {
  }

  from(any: any): DashboardConfig {
    this.logoUrl = any.logoUrl;
    this.title = any.title;
    this.showAdmins = any.showAdmins;
    this.showShops = any.showShops;
    this.showUsers = any.showUsers;
    this.showGroups = any.showGroups;
    this.showCredits = any.showCredits;
    this.customMetrics = any.customMetrics;
    this.requestPasswordChangeUrl = any.requestPasswordChangeUrl;
    this.showUserPaymentLate = any.showUserPaymentLate;
    this.showUserFunnel = any.showUserFunnel;
    this.showJuntasFunnel = any.showJuntasFunnel;
    this.showUsualUsers = any.showUsualUsers;
    this.showBsUsersFunnel = any.showBsUsersFunnel;
    this.showEndUsers = any.showEndUsers;
    this.showHeaderNotification = any.showHeaderNotification;
    this.savingPlans = any.savingPlans;
    this.paymentList = any.paymentList;
    this.showReportUser = any.showReportUser;
    return this;

  }
}
