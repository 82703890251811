<div class="branding">
  <ng-container *ngIf="dashboardConfigService.dashboardConfig$ | async as config">
    <ng-container *ngIf="config.title?.startsWith('Quantum'); else onlyText">
      <img [src]="config.logoUrl" alt="Quantum logo" class="brand-logo">
    </ng-container>
    <ng-template #onlyText>
      <span class="app-logo-text">{{config.title}}</span>
    </ng-template>
  </ng-container>

</div>

<mat-nav-list class='sidebar-container'>
  <div class="sidebar-items">
    <mat-divider></mat-divider>
      <button
        mat-button
        routerLink="analytics"
        routerLinkActive="mat-mdc-raised-button mat-primary"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        Inicio
      </button>
    <mat-divider></mat-divider>

    <button
      mat-button
      routerLink="custom-metrics"
      *ngIf="((dashboardConfigService.dashboardConfig$ | async)?.customMetrics)"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Métricas
    </button>

    <button
      mat-button
      *ngIf="(dashboardConfigService.dashboardConfig$ | async)?.showShops"
      routerLink="shops"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Comercios
    </button>

    <button
      mat-button
      *ngIf="(dashboardConfigService.dashboardConfig$ | async)?.showGroups"
      routerLink="groups"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Grupos
    </button>
    <button
      mat-button
      routerLink="admins"
      *ngIf="((dashboardConfigService.dashboardConfig$ | async)?.showAdmins) && (isAdmin$ | async)"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Administradores
    </button>
    <button
      mat-button
      routerLink="applications"
      *ngIf="((dashboardConfigService.dashboardConfig$ | async)?.showCredits) && (isAdmin$ | async)"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Créditos
    </button>
    <button
      mat-button
      routerLink="users"
      *ngIf="((dashboardConfigService.dashboardConfig$ | async)?.showUsers)"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Usuarios
    </button>
    <button
      mat-button
      routerLink="user-payment-late"
      *ngIf="(dashboardConfigService.dashboardConfig$ | async)?.showUserPaymentLate"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Usuarios en mora
    </button>
    <button
      mat-button
      routerLink="user-funnel"
      *ngIf="(dashboardConfigService.dashboardConfig$ | async)?.showUserFunnel"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Funnel de usuarios
    </button>
    <button
      mat-button
      routerLink="bsol-user-funnel"
      *ngIf="(dashboardConfigService.dashboardConfig$ | async)?.showBsUsersFunnel"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Funnel de usuarios
    </button>
    <button
      mat-button
      routerLink="juntas-funnel"
      *ngIf="(dashboardConfigService.dashboardConfig$ | async)?.showJuntasFunnel"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Funnel de juntas
    </button>
    <button
      mat-button
      routerLink="usuer-usual-funnel"
      *ngIf="(dashboardConfigService.dashboardConfig$ | async)?.showUsualUsers"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Funnel de usuarios usuales
    </button>
    <button
      mat-button
      routerLink="report-user"
      *ngIf="(dashboardConfigService.dashboardConfig$ | async)?.showReportUser"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Descargar reporte de usuarios
    </button>

    <button
      mat-button
      routerLink="bcp/metrics-view"
      *ngIf="((dashboardConfigService.dashboardConfig$ | async)?.savingPlans) && (isAdmin$ | async)"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Métricas
    </button>

    <button
      mat-button
      routerLink="bcp/end-users"
      *ngIf="canSeeEndUsers$ | async"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Finalistas
    </button>

    <button
      mat-button
      routerLink="bcp/saving-plans"
      *ngIf="canSeeSavingPlans$ | async"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Planes de ahorro
    </button>

    <button
      mat-button
      routerLink="bcp/payments"
      *ngIf="canSeePayments$ | async"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Lista de abonos
    </button>

    <button
      mat-button
      routerLink="bcp/payments-review"
      *ngIf="canSeePayments$ | async"
      routerLinkActive="mat-mdc-raised-button mat-primary"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      Revisión de abonos
    </button>

  </div>
  <div>
    <mat-divider></mat-divider>
    <div class="logout-button" *ngIf="user$ | async as user">
      <div class="admin-name">
        <span class="material-icons">person_outline</span>
        <p class="mat-body">{{ (user?.displayName | titlecase) ?? 'Usuario' }}</p>
      </div>
      <button mat-icon-button (click)="logOut()"><mat-icon>logout</mat-icon></button>
    </div>
  </div>
</mat-nav-list>
